import React, { useState } from 'react';
import './Login.css'; // Import your CSS file for styling
import { useNavigate } from "react-router-dom";

function Login(props) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [authenticated, setAuthenticated] = useState(false);
  const navigate = useNavigate();

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleLogin = () => {
    // Base64 encode the provided values
    const encodedUsername = btoa(username);
    const encodedPassword = btoa(password);

    // Compare with the predefined values
    const predefinedUsername = 'amFyZWQ='; //btoa('jared');
    const predefinedPassword = 'SmFyZWRAMjAyMyM='; // btoa('Jared@2023#');

    if (encodedUsername === predefinedUsername && encodedPassword === predefinedPassword) {
      props.setLoginStatus(true);
      navigate("/all-users");
    } else {
      alert('Invalid username or password');
    }
  };

  return (
    <div className="login-container">
      <div className="login-form">
        <h2>Login</h2>
        <div>
          <label>Username:</label>
          <input
            type="text"
            value={username}
            onChange={handleUsernameChange}
          />
        </div>
        <div>
          <label>Password:</label>
          <input
            type="password"
            value={password}
            onChange={handlePasswordChange}
          />
        </div>
        <button onClick={handleLogin}>Login</button>
        {authenticated && <p>Logged in successfully!</p>}
      </div>
    </div>
  );
}

export default Login;
