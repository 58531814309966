import React, { useEffect, useState } from "react";
import { Layout, Menu } from "antd";
import "./App.css";
import { QueryClientProvider, queryClient } from "./queryClient";
import { BrowserRouter, NavLink, Route, Routes } from "react-router-dom";
import UserList from "./UserList";
import PrrUserList from "./PrrUserList";
import Login from "./Login";
import DiscriminatedPrisoners from "./DiscriminatedPrisoners";

const { Header, Content, Footer } = Layout;

function App() {
  const [isLogin, setLoginStatus] = useState(false);


  const URL = window.location.href;
  

  useEffect(() => {
    console.log(URL, isLogin, URL[URL.length-1]);
    if(URL[URL.length-1] !== "/" && !isLogin){
      onLogoutClick();
    }
  }, [isLogin]);


  const onLogoutClick = () => {
    setLoginStatus(false);
    window.location.replace("/");
  }
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <div>
          <Layout>
            {isLogin && <Header style={{ height: "auto" }}>
              <div className="logo" />
              <h1 style={{ color: "white" }}>Users</h1>
              <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['1']}>
                <Menu.Item key="1">
                  <NavLink to="/all-users">All Users</NavLink>
                </Menu.Item>
                <Menu.Item key="2">
                  <NavLink to="/prr-users">PRR Users</NavLink>
                </Menu.Item>
                <Menu.Item key="3">
                  <NavLink to="/non-discriminated-prisoners">Non-Discriminated Prisoners</NavLink>
                </Menu.Item>
                <Menu.Item key="100" style={{ marginLeft: 'auto' }}>
                  <NavLink onClick={onLogoutClick}>Logout</NavLink>
                </Menu.Item>
              </Menu>
            </Header>}
            <Content style={{ padding: "0 50px", marginTop: "20px" }}>
              <div className="site-layout-content">
                <Routes>
                  <Route path="/" element={<Login setLoginStatus={setLoginStatus}/>}></Route>
                  <Route path="/prr-users" element={<PrrUserList />}></Route>
                  <Route path="/non-discriminated-prisoners" element={<DiscriminatedPrisoners />}></Route>
                  <Route path="all-users" element={<UserList />}></Route>
                </Routes>
              </div>
            </Content>
            <Footer style={{ textAlign: "center" }}>
              FDOC ©2023 Created by <a target="_blank" href="https://goforsys.com">GoForSys Technologies Pvt Ltd</a>
            </Footer>
          </Layout>
        </div>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
