import React, { useState, useEffect } from 'react';
import { Select, Table, Tooltip } from 'antd';
import axios from 'axios';
import { useQuery } from 'react-query';
import moment from "moment";
const { Option } = Select;

// Columns for Table (same as in PrrUserList or adjust as needed)

const columns = [
  {
    title: "DC Number",
    dataIndex: "DCNumber",
    key: "DCNumber",
  },
  {
    title: "Last Name",
    dataIndex: "LastName",
    key: "LastName",
  },
  {
    title: "First Name",
    dataIndex: "FirstName",
    key: "FirstName",
  },
  {
    title: "Middle Name",
    dataIndex: "MiddleName",
    key: "MiddleName",
  },
  {
    title: "Race",
    dataIndex: "Race",
    key: "Race",
  },
  {
    title: "Sex",
    dataIndex: "Sex",
    key: "Sex",
    render: (text) => {
      const labelClassName =
        text === "M" ? "label-male" : text === "F" ? "label-female" : "";

      return <Tooltip title={text === "M" ? "Male" : "Female"}><span className={labelClassName}>{text}</span></Tooltip>;
    },
  },
  {
    title: "Birth Date",
    dataIndex: "BirthDate",
    key: "BirthDate",
    render: (text) => moment(text).format("MMMM DD, YYYY"), // Format the date with moment.js
  },
  {
    title: "Charge",
    dataIndex: "adjudicationcharge_descr",
    key: "adjudicationcharge_descr",
  },
  {
    title: "Facility Description",
    dataIndex: "FACILITY_description",
    key: "FACILITY_description",
  },
  {
    title: "County",
    dataIndex: "County",
    key: "County",
  },
];


// Function to fetch unique offenses
const fetchUniqueOffenses = async () => {
  const response = await axios.get('http://api.fdoc.cloud/api/get-unique-prr-offense');
  return response.data;
};

// Function to fetch non-PRR users
const fetchNonPrrUsers = async (search) => {
  //http://api.fdoc.cloud
  const response = await axios.get(`http://api.fdoc.cloud/api/get-non-prr-users?search=${search}`);
  return response.data;
};

const DiscriminatedPrisoners = () => {
  const [selectedOffenses, setSelectedOffenses] = useState([]);

  // Fetch unique offenses for dropdown
  const { data: offensesData } = useQuery('uniqueOffenses', fetchUniqueOffenses);

  // Fetch non-PRR users based on selected offenses
  const { data: usersData, isLoading } = useQuery(
    ['nonPrrUsers', { search: selectedOffenses.join('__') }],
    () => fetchNonPrrUsers(selectedOffenses.join('__')),
    {
      // Only fetch when there are selected offenses
      enabled: selectedOffenses.length > 0,
    }
  );

  // Handle change in selection
  const handleSelectChange = (value) => {
    setSelectedOffenses(value);
  };

  return (
    <div>
      <Select
        mode="multiple"
        style={{ width: '100%', marginBottom: 16 }}
        placeholder="Select offenses"
        onChange={handleSelectChange}
        allowClear
      >
        {offensesData?.offenses.map((offense) => (
          <Option key={offense.PrimaryOffenseDescription} value={offense.PrimaryOffenseDescription}>
            {offense.PrimaryOffenseDescription}
          </Option>
        ))}
      </Select>
      <Table
        columns={columns}
        dataSource={usersData?.users || []}
        loading={isLoading}
        // Add pagination props if needed
      />
    </div>
  );
};

export default DiscriminatedPrisoners;
