import React, { useState } from "react";
import { Table, Input, Tooltip } from "antd";
import axios from "axios";
import { debounce } from "lodash";
import moment from "moment";
import { useQuery } from "react-query";

const columns = [
  {
    title: "DC Number",
    dataIndex: "DCNumber",
    key: "DCNumber",
  },
  {
    title: "Last Name",
    dataIndex: "LastName",
    key: "LastName",
  },
  {
    title: "First Name",
    dataIndex: "FirstName",
    key: "FirstName",
  },
  {
    title: "Primary Offense Description",
    dataIndex: "PrimaryOffenseDescription",
    key: "PrimaryOffenseDescription",
  },
  {
    title: "Prison Years",
    dataIndex: "PrisonYears",
    key: "PrisonYears",
  },
  {
    title: "Race",
    dataIndex: "Race",
    key: "Race",
  },
];

const fetchPrrUsers = async ({ page, limit, search }) => {
  const response = await axios.get(
    `http://api.fdoc.cloud/api/prr-user?page=${page}&limit=${limit}&search=${search}`
  );
  return response.data;
};

const PrrUserList = () => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState("");

  const debouncedSearch = debounce((value) => {
    setSearch(value);
    setPage(1); // Reset page to 1 when searching
  }, 300);

  const { data, isLoading } = useQuery(
    ["prr-users", { page, limit, search }],
    () => fetchPrrUsers({ page, limit, search })
  );

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handlePageSizeChange = (current, newSize) => {
    setLimit(newSize);
    setPage(1); // Reset page to 1 when changing page size
  };

  return (
    <div>
      <Input
        placeholder="Search..."
        onChange={(e) => debouncedSearch(e.target.value)}
        style={{ width: 200, marginBottom: 16 }}
        allowClear
      />
      <Table
        columns={columns}
        dataSource={data?.data || []}
        loading={isLoading}
        pagination={{
          current: page,
          pageSize: limit,
          total: data?.totalRecords || 0,
          onChange: handlePageChange,
          onShowSizeChange: handlePageSizeChange,
          showSizeChanger: true,
        }}
      />
    </div>
  );
};

export default PrrUserList;
