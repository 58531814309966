// src/UserList.js
import React, { useState } from "react";
import { Table, Input, Tooltip } from "antd";
import axios from "axios";
import { debounce } from "lodash";
import moment from "moment";
import { useQuery } from "react-query";

const columns = [
  {
    title: "DC Number",
    dataIndex: "DCNumber",
    key: "DCNumber",
  },
  {
    title: "Last Name",
    dataIndex: "LastName",
    key: "LastName",
  },
  {
    title: "First Name",
    dataIndex: "FirstName",
    key: "FirstName",
  },
  {
    title: "Middle Name",
    dataIndex: "MiddleName",
    key: "MiddleName",
  },
  {
    title: "Race",
    dataIndex: "race_descr",
    key: "race_descr",
  },
  {
    title: "Sex",
    dataIndex: "Sex",
    key: "Sex",
    render: (text) => {
      const labelClassName =
        text === "M" ? "label-male" : text === "F" ? "label-female" : "";

      return <Tooltip title={text === "M" ? "Male" : "Female"}><span className={labelClassName}>{text}</span></Tooltip>;
    },
  },
  {
    title: "Birth Date",
    dataIndex: "BirthDate",
    key: "BirthDate",
    render: (text) => moment(text).format("MMMM DD, YYYY"), // Format the date with moment.js
  },
  {
    title: "Table Field",
    dataIndex: "table_field",
    key: "table_field",
  },
];


const fetchUsers = async ({ page, limit, search }) => {
    const response = await axios.get(`http://api.fdoc.cloud/api/users?page=${page}&limit=${limit}&search=${search}`);
    return response.data;
  };

const UserList = () => {

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState("");
  
  const debouncedSearch = debounce((value) => {
    setSearch(value);
    setPage(1); // Reset page to 1 when searching
  }, 300);


  const { data, isLoading } = useQuery(
    ['users', { page, limit, search}],
    () => fetchUsers({ page, limit, search})
  );

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handlePageSizeChange = (current, newSize) => {
    setLimit(newSize);
    setPage(1); // Reset page to 1 when changing page size
  };


  const formattedData = data?.users?.map((item) => ({
    ...item,
    BirthDate: moment(item.BirthDate).format("MMMM DD, YYYY"), // Format the date with moment.js
  }));

  return (
    <div>
      <Input
        placeholder="Search..."
        onChange={(e) => debouncedSearch(e.target.value)}
        style={{ width: 200, marginBottom: 16 }}
        allowClear
      />
      <Table
        columns={columns}
        dataSource={formattedData}
        loading={isLoading}
        key={'DCNumber'}
        pagination={{
          current: page,
          pageSize: limit,
          total: data?.totalRecords || 0,
          onChange: handlePageChange,
          onShowSizeChange: handlePageSizeChange,
          showSizeChanger: true,
        }}
      />
    </div>
  );
};

export default UserList;
